<template>
  <div class="main">
    <!-- 订单详情提出订单时弹出 -->
    <Dialog ref="dia1" :config="config">
      <div>
        <p>
          Estimado/a {{ $store.state.userInfo.user_name }}, actualmente se
          encuentra en la página de envío de pedidos,
        </p>
        <p>
          Puede ajustar la cantidad de bienes en la página actual y seleccionar
          los servicios adicionales que desee. Si tiene otros asuntos que
          requieren nuestra atención, puede apuntarlo en la parte posterior del
          producto o en los comentarios del pedido. Procesaremos de acuerdo con
          sus comentarios.
        </p>
        <p>
          Después de confirmar que no hay ningún problema con el pedido, haga
          clic en el botón en la esquina inferior derecha para realizar el
          pedido.
        </p>
        <p>
          Confirmaremos el estado del inventario de los productos en su pedido,
          la tarifa de envío nacional en China y si está prohibido o no.
        </p>
        <p>Después de la confirmación, le enviaremos la cotización.</p>
        <p>
          En ese momento, recibirá un correo electrónico con una cotización.
          Puede dirigirse a 【Mi cuenta】-【Pedido】-【Pendiente de pago】 para
          consultar los detalles de la cotización específica.
        </p>
      </div>
    </Dialog>
    <!-- 订单详情待付款时弹出 -->
    <Dialog ref="dia2" :config="config">
      <div>
        <p>
          Estimado/a {{ $store.state.userInfo.user_name }}, actualmente se
          encuentra la página del pedido de pago,
        </p>
        <p>
          Puede ver la información de cotización específica en la página actual,
        </p>
        <p>
          Si necesita ajustar el pedido, haga clic en el botón 【Cambios en el
          pedido】 en la esquina superior derecha para editar el pedido
          nuevamente. Le volveremos a confirmar la cotización en función de su
          pedido reenviado.
        </p>
        <p>
          Después de confirmar que no hay problema, puede hacer clic en el botón
          【Pago】 para pagarlo.
        </p>
        <p>
          Después de que lleguen los productos, los pondremos en su 【Almacén】.
          Si hay un problema con el producto, lo confirmaremos a través de
          【Producto defectuoso】 o del Chat.
        </p>
      </div>
    </Dialog>
  </div>
</template>
<script>
import Dialog from "../../../../../../components/public/Dialog.vue";
export default {
  data() {
    return {
      config: {
        top: "20vh",
        width: "800px",
        title: "Atención",
        btnTxt: ["Cerrar"],
      },
    };
  },
  components: { Dialog },
  computed: {
    orderStatus() {
      return this.$parent.orderStatus;
    },
  },
  mounted() { },
  methods: {
    openOrderDia() {
      if (this.$route.query.id) {
        this.$refs.dia1.open("", () => { }, "");
      }

      if (this.orderStatus == "obligation") {
        this.$refs.dia2.open("", () => { }, "");
      }
      this.$forceUpdate();
    },
  },
};
</script>
<style lang='scss' scoped='scoped'>
@import "../../../../../../css/mixin.scss";

.main {}

p {
  font-size: 16px;
  text-indent: 1cm;
  margin-bottom: 10px;
}
</style>