<template>
  <div class="cent">
    <header class="flexAndCenter">
      <div>{{ $fanyi('商品金额变动明细') }}</div>
      <img src="../../../../../../assets/cancel.png" @click="$emit('cloneGoodsPriceUpdateDetailDialogVisible', false)"
        alt="" />
    </header>
    <section>
      <ul class="goodsPriceUpdateDetailTableHeader flexAndCenter">
        <li class="serialNumber" style="width: 70px;">{{ $fanyi('序号') }}</li>
        <li class="beforeAdjustment marginRight20">
          {{ $fanyi('调整前数据') }}
        </li>
        <li class="beforeAdjustment marginRight20">
          {{ $fanyi('调整后数据') }}
        </li>
        <!-- <li class="outMoneyNumber marginRight20">{{ $fanyi('出入金编号') }}</li> -->

        <li class="outMoneyNumber marginRight20">{{ $fanyi('调整时间') }}</li>
        <li class="outMoneyNumber marginRight20">
          {{ $fanyi('调整具体明细') }}
        </li>
        <li class="beforeAdjustment marginRight20" style="width: 170px;">
          {{ $fanyi('金额') }}($)
        </li>
      </ul>
      <ul class="goodsPriceUpdateDetailTableFooter flexAndCenter" v-for="(item, index) in Changedetail" :key="index">
        <li class="serialNumber">{{ index + 1 }}</li>
        <li class="beforeAdjustment marginRight20 num">
          <div v-for="(i, index) in item.detail" :key="index">
            ${{ $fanyi(i.key) }} : {{ i.val }}<span v-if="index != 0"></span>
          </div>
        </li>
        <li class="beforeAdjustment marginRight20 num">
          <div v-for="(i, index) in item.detail_edit" :key="index">
            ${{ $fanyi(i.key) }} : {{ i.val }}<span v-if="index != 0"></span>
          </div>
        </li>
        <!-- <li
          class="outMoneyNumber marginRight20"
          style="color:#FF730B;text-decoration: underline;cursor: pointer"
        >
          9137829749
        </li> -->

        <li class="outMoneyNumber marginRight20">{{ item.data_time
        }}</li>
        <li class="outMoneyNumber marginRight20">
          <el-popover placement="bottom" trigger="hover">
            <div class="inputOption haveData">
              <p class="list" v-for="(eeitem, optionIndex) in item.adjust_detail" :key="optionIndex">
                {{ eeitem.index }}&nbsp;{{ eeitem }}
              </p>
            </div>
            <div slot="reference">
              <div class="inputOption haveData">
                <p class="list" v-for="(eeitem, optionIndex) in item.adjust_detail" :key="optionIndex">
                  {{ eeitem.index }}&nbsp;{{ eeitem }}arr
                </p>
              </div>
            </div>
          </el-popover>
        </li>
        <li class="outMoneyNumber marginRight20" style="font-weight: 600;">
          {{ item.amount }}
        </li>
      </ul>
      <div class="Noadjustment" v-if="Changedetail.length == 0">{{ $fanyi('没有调整') }}</div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'goodsPriceUpdateDetail',
  data() {
    return {
      Changedetail: [],
    }
  },
  created() {

  },
}
</script>

<style lang="scss" scoped>
/deep/ .el-dialog {
  width: 1000px !important;
  background-color: transparent;
  border-radius: 6px;
}

.cent {
  width: 1000px !important;
  background-color: transparent;
  border-radius: 6px;
  overflow: hidden;
}

.Noadjustment {
  width: 100%;
  height: 120px;
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #999999;
  text-align: center;
  line-height: 120px;
  border-bottom: 1px solid #e2e2e2;
}

header {
  justify-content: space-between;
  padding: 20px 30px;
  border: 1px solid #e2e2e2;

  div {
    color: #222222;
    font-size: 18px;
    font-weight: 500;
  }

  img {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
}

section {
  padding: 20px 30px;

  .serialNumber {
    min-width: 80px;
    // display: flex;
    // align-items: center;
  }

  .beforeAdjustment {
    width: 120px;
    margin: auto;

  }

  .outMoneyNumber {
    width: 160px;
  }

  .marginRight20 {
    margin-right: 20px;
  }

  .goodsPriceUpdateDetailTableHeader {
    height: 60px;
    background: #f6f6f6;
    border-radius: 6px;
    height: 60px;
    background: #f9f9f9;

    li {
      // line-height: 60px;
      text-align: center;
      font-size: 14px;
      color: #222;
      font-weight: 500;
    }
  }

  .num {
    text-align: left !important;
  }

  .goodsPriceUpdateDetailTableFooter {
    height: 120px;
    background-color: #fff;
    display: flex;

    li {
      text-align: center;
      font-size: 14px;
      color: #222;

      .inputOption {
        width: 100%;
        height: 120px;
        max-height: 100px;
        overflow-y: auto;

        &.haveData {
          background-color: white;
          border: 1px solid #ededed;
          color: #a7a7a7;
        }

        .placeHole {
          background-color: white;
          border: 1px solid #ededed;
          text-align: left;
          padding: 10px;
          line-height: 12px;
          position: relative;

          i {
            position: absolute;
            right: 5px;
            top: 11px;
            font-size: 12px;
            z-index: 0;
          }
        }

        .placeTiShi {
          color: #a7a7a7;
          font-size: 11px;
          word-break: break-all;
          text-align: left;
        }

        .list {
          text-align: left;
          padding-left: 10px;
        }
      }
    }
  }

  .goodsPriceUpdateDetailTableFooter:hover {
    background-color: #f9f9f9;
  }
}
</style>
