<template>
  <div class="tihuan">
    <DialogV ref="dialog" :config="config">
      <div class="tishiCon">
        <h1 class="tishiTile">
          {{ $fanyi('替换商品') }}
          <i class="el-icon-close" @click="closeDia"></i>
        </h1>
        <div class="xian"></div>
        <div class="goodsBlock oldGoods">
          <h2>
            {{
              goodsToBeReplaced.num > goodsToBeReplaced.confirm_num
              ? $fanyi('库存不足商品')
              : $fanyi('当前商品')
            }}
          </h2>
          <div class="goodsArrTable">
            <div class="arrtableHead">
              <div class="goodsArrTd goodsArrHe dianpuming">
                {{ $fanyi('店铺') }}
              </div>
              <div class="goodsArrTd goodsArrHe shangpintupian">
                {{ $fanyi('商品图片') }}
              </div>
              <div class="goodsArrTd goodsArrHe shangpinbiaoti">
                {{ $fanyi('产品') }}
              </div>
              <div class="goodsArrTd goodsArrHe shangpinguige">
                {{ $fanyi('属性') }}
              </div>
              <div class="goodsArrTd goodsArrHe xiadanshuliang">
                {{ $fanyi('数量') }}
              </div>
              <div class="goodsArrTd goodsArrHe zaikushu">
                {{ $fanyi('在库数') }}
              </div>
              <div class="goodsArrTd goodsArrHe baojiajiage">
                {{ $fanyi('单价') }}
              </div>
              <div class="goodsArrTd goodsArrHe guoneiyunfei"></div>
              <div class="goodsArrTd goodsArrHe caozuo"></div>
            </div>
            <div class="arrtableBody">
              <div class="arrtableRow">
                <div class="goodsArrTd goodsArrBo dianpuming">
                  {{ shopName }}
                </div>
                <div class="goodsArrTd goodsArrBo shangpintupian">
                  <el-popover placement="right" trigger="hover">
                    <img :src="goodsToBeReplaced.pic" alt="" style="width: 300px; height: 300px" />
                    <el-image :src="goodsToBeReplaced.pic" slot="reference">
                      <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                      </div>
                      <div slot="placeholder">
                        <i class="el-icon-loading"></i>
                      </div>
                    </el-image>
                  </el-popover>
                </div>
                <div class="goodsArrTd goodsArrBo shangpinbiaoti">
                  <el-popover placement="bottom" width="400" trigger="hover">
                    <span>{{ goodsToBeReplaced.goods_title }}</span>
                    <div slot="reference">
                      {{ goodsToBeReplaced.goods_title }}
                    </div>
                  </el-popover>
                </div>
                <div class="goodsArrTd goodsArrBo shangpinguige">
                  <el-popover placement="bottom" width="" trigger="hover">
                    <p v-for="(detailItem,
                      detailIndex) in goodsToBeReplaced.detail" :key="'goodsdetailIndex' + detailIndex">
                      {{ detailItem.key }}:{{ detailItem.value }}
                    </p>

                    <div slot="reference" class="tdProinSpan">
                      <p v-for="(detailItem,
                        detailIndex) in goodsToBeReplaced.detail" :key="'goodsdetailIndex' + detailIndex">
                        {{ detailItem.key }}:{{ detailItem.value }}
                      </p>
                    </div>
                  </el-popover>
                </div>
                <div class="goodsArrTd goodsArrBo xiadanshuliang">
                  {{ goodsToBeReplaced.num }}
                </div>
                <div class="goodsArrTd goodsArrBo zaikushu">
                  {{ goodsToBeReplaced.confirm_num }}
                </div>
                <div class="goodsArrTd goodsArrBo baojiajiage">
                  <span>
                    ${{
                      $fun.RMBNumSegmentation(
                        $fun.ceil(
                          goodsToBeReplaced.confirm_price *
                          $parent.datas.exchange_rate
                        )
                      )
                    }}</span>
                  <span>({{
                    $fun.RMBNumSegmentation(goodsToBeReplaced.confirm_price)
                  }}￥)</span>
                </div>
                <!-- 代替换商品表格的后两列凑数用 -->
                <div class="goodsArrTd goodsArrBo guoneiyunfei"></div>
                <div class="goodsArrTd goodsArrBo caozuo"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="goodsBlock newGoods">
          <h2>{{ $fanyi('可选择替换的商品') }}</h2>
          <div class="goodsArrTable">
            <div class="arrtableHead">
              <div class="goodsArrTd goodsArrHe dianpuming">
                {{ $fanyi('店铺') }}
              </div>
              <div class="goodsArrTd goodsArrHe shangpintupian">
                {{ $fanyi('商品图片') }}
              </div>
              <div class="goodsArrTd goodsArrHe shangpinbiaoti">
                {{ $fanyi('产品') }}
              </div>
              <div class="goodsArrTd goodsArrHe shangpinguige">
                {{ $fanyi('属性') }}
              </div>
              <div class="goodsArrTd goodsArrHe xiadanshuliang">
                {{ $fanyi('数量') }}
              </div>
              <div class="goodsArrTd goodsArrHe zaikushu">
                {{ $fanyi('在库数') }}
              </div>
              <div class="goodsArrTd goodsArrHe baojiajiage">
                {{ $fanyi('单价') }}
              </div>
              <div class="goodsArrTd goodsArrHe guoneiyunfei">
                {{ $fanyi('国内运费') }}
              </div>
              <div class="goodsArrTd goodsArrHe caozuo"></div>
            </div>
            <div class="arrtableBody" v-if="SustituciónDeBienes.length > 0">
              <div class="arrtableRow" v-for="(goodsItem, goodsIndex) in SustituciónDeBienes"
                :key="'tihuanGoods' + goodsIndex">
                <div class="goodsArrTd goodsArrBo dianpuming">
                  {{ goodsItem.shop_name }}
                </div>
                <div class="goodsArrTd goodsArrBo shangpintupian">
                  <el-popover placement="right" trigger="hover">
                    <img :src="goodsItem.pic" alt="" style="width: 300px; height: 300px" />
                    <el-image :src="goodsItem.pic" slot="reference">
                      <div slot="error" class="image-slot">
                        <i class="el-icon-picture-outline"></i>
                      </div>
                      <div slot="placeholder">
                        <i class="el-icon-loading"></i>
                      </div>
                    </el-image>
                  </el-popover>
                </div>
                <div class="goodsArrTd goodsArrBo shangpinbiaoti">
                  <el-popover placement="bottom" width="400" trigger="hover">
                    <span>{{ goodsItem.goods_title }}</span>
                    <div slot="reference">
                      {{ goodsItem.goods_title }}
                    </div>
                  </el-popover>
                </div>
                <div class="goodsArrTd goodsArrBo shangpinguige">
                  <el-popover placement="bottom" width="" trigger="hover">
                    <p v-for="(detailItem, detailIndex) in JSON.parse(
                      goodsItem.detail
                    )" :key="'goodsdetailIndex' + detailIndex">
                      {{ detailItem.key }}:{{ detailItem.value }}
                    </p>

                    <div slot="reference" class="tdProinSpan">
                      <p v-for="(detailItem, detailIndex) in JSON.parse(
                        goodsItem.detail
                      )" :key="'goodsdetailIndex' + detailIndex">
                        {{ detailItem.key }}:{{ detailItem.value }}
                      </p>
                    </div>
                  </el-popover>
                </div>
                <div class="goodsArrTd goodsArrBo xiadanshuliang">
                  {{ goodsItem.num }}
                </div>
                <div class="goodsArrTd goodsArrBo zaikushu">
                  {{ goodsItem.confirm_num }}
                </div>
                <div class="goodsArrTd goodsArrBo baojiajiage">
                  <span>
                    $
                    {{
                      $fun.RMBNumSegmentation(
                        $fun.ceil(
                          goodsItem.confirm_price * $parent.datas.exchange_rate
                        )
                      )
                    }}</span>
                  <span>({{
                    $fun.RMBNumSegmentation(goodsItem.confirm_price)
                  }}￥)</span>
                </div>
                <div class="goodsArrTd goodsArrBo guoneiyunfei">
                  {{
                    $fun.EURNumSegmentation(goodsItem.confirm_dicker_freight)
                  }}
                </div>
                <div class="goodsArrTd goodsArrBo caozuo">
                  <el-button type="primary" class="chioseBtn" @click="confirmeElReemplazo(goodsItem.id)">{{
                    $fanyi('选择该商品') }}</el-button>
                </div>
              </div>
            </div>
            <!-- <div class="noData">
              {{ $fanyi('暂无数据') }}
            </div> -->
          </div>
        </div>
      </div>
    </DialogV>
    <span class="tihuanFont" @click="replaceGoods">
      {{

        $fanyi('替换产品')
      }}
    </span>
  </div>
</template>
<script>
import DialogV from '../../../../../../components/public/Dialog.vue'
export default {
  props: {
    goodsToBeReplaced: {
      default: {},
      type: Object,
    },
    SustituciónDeBienes: {
      default: () => {
        return []
      },
      type: Array,
    },
    shopName: {
      default: '',
      type: String,
    },
  },

  data() {
    return {
      config: {
        top: '20vh',
        width: '1440px',
        title: '提示',
        btnTxt: ['删除', '取消'],
      },
    }
  },
  components: { DialogV },
  computed: {},
  mounted() { },
  methods: {
    // 关闭弹窗事件
    closeDia() {
      this.$refs.dialog.cancel()
    },
    // 打开替换商品弹窗
    replaceGoods() {
      this.$refs.dialog.open(
        '',
        () => { },
        () => {
          this.$message('已取消删除')
        }
      )
    },
    // 确认选择该商品事件
    confirmeElReemplazo(goodsId) {
      this.$api
        .replaceGoods({
          replace_id: goodsId,
        })
        .then((res) => {
          if (res.code != 0) return this.$message.error(res.meg)
          this.$parent.pageLoad()
          this.$refs.dialog.cancel()
        })
    },
  },
}
</script>
<style lang="scss" scoped="scoped">
@import '../../../../../../css/mixin';

/deep/.el-dialog__header {
  height: 68px !important;
  border-bottom: 2px solid #e2e2e2 !important;

  .el-dialog__close {
    font-size: 18px;
    color: #333;
    font-weight: 600;
  }

  display: none;
}

/deep/.el-dialog {
  width: 1400px !important;
  height: 850px !important;
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid #e2e2e2;
}

/deep/.el-dialog__footer {
  display: none;
}

/deep/.el-dialog__body {
  padding: 20px !important;
}

.tishiCon {
  .tishiTile {
    height: 22px;
    font-size: 22px;
    font-weight: 400;
    color: #2f2f2f;
    line-height: 22px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .el-icon-close {
      cursor: pointer;
    }
  }

  .xian {
    width: 1400px !important;
    height: 1px !important;
    overflow: hidden;
    background: #e2e2e2;
    margin-left: -20px;
  }

  .goodsBlock {
    padding-top: 30px;

    >h2 {
      font-size: 16px;

      max-width: 260px;
      height: 40px;
      background: #fff2e8;
      border-radius: 4px;
      font-family: Roboto-Medium, Roboto;
      font-weight: 500;
      text-align: center;
      color: #ff730b;
      line-height: 40px;
      margin-bottom: 20px;
    }

    .goodsArrTable {
      width: 1360px;

      margin: 0 auto;

      .noData {
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .arrtableHead {
        height: 60px;
        background: #f6f6f6;
        border-radius: 6px !important;
        background: #f9f9f9;
        display: flex;

        align-items: center;

        .goodsArrTd {
          font-weight: 500;
          font-size: 14px;
          font-family: PingFang-SC-Medium, PingFang-SC;
          font-weight: 500;
          color: #222222;
          text-align: center;
        }
      }

      .arrtableBody {
        height: 220px;
        overflow: auto;
        padding: 10px 0 30px;

        .arrtableRow {
          display: flex;
          align-items: center;
          align-items: flex-start;

          background: #fff5ee;
          padding: 20px 0;
        }
      }

      .goodsArrTd {
        flex: 1;
        font-size: 14px;
        font-size: 14px;
        color: #222222;
        line-height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 10px;
        $goodsTdHeight: 80px; //单元格内容高度

        &.goodsArrBo {
          height: $goodsTdHeight;
        }

        &.xiadanshuliang {
          &.goodsArrBo {
            font-weight: bold;
          }
        }

        &.zaikushu {
          &.goodsArrBo {
            font-weight: bold;
          }
        }

        &.shangpintupian {
          &.goodsArrBo {
            /deep/.el-image {
              $imgSize: 80px;
              width: $imgSize;
              height: $imgSize;
              transition: 0.3s;
              text-align: center;

              i {
                font-size: $imgSize/4;
                line-height: $imgSize;
              }
            }
          }
        }

        &.baojiajiage {
          &.goodsArrBo {
            flex-wrap: wrap;
            font-weight: bold;
            line-height: 22px;
          }
        }

        &.shangpinguige {
          &.goodsArrBo {
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            position: relative; //因为设计图上字间距是按照顶部对齐的，所以向上移动
            top: -3.75px;
            height: $goodsTdHeight + 3.75px !important;
            font-weight: 400;
            color: #2f2f2f;
            line-height: 22px;
            height: 80px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-flex;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
          }
        }

        &.shangpinbiaoti {
          &.goodsArrBo {
            position: relative; //因为设计图上字间距是按照顶部对齐的，所以向上移动
            top: -3.75px;
            height: $goodsTdHeight + 3.75px !important;

            font-weight: 400;
            color: #2f2f2f;
            line-height: 22px;
            height: 80px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
          }
        }

        &.caozuo {
          .chioseBtn {
            width: 140px;
            height: 40px;
            background: #ff730b;
            border-radius: 4px;
            background: #ff730b;
            border: 1px solid #ff730b;
            font-size: 14px;
            font-family: Roboto-Regular, Roboto;
            font-weight: 400;
            color: #ffffff;
            border-radius: 0px;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          /deep/ .el-button {
            border-radius: 4px;
          }
        }
      }
    }

    &.oldGoods {
      border-bottom: solid 1px#E2E2E2;
    }

    &.newGoods {
      h2 {
        max-width: 260px;
        height: 40px;
        background: #eff4ff;
        border-radius: 4px;
        line-height: 40px;
        text-align: center;
        font-size: 16px;
        font-family: Roboto-Medium, Roboto;
        font-weight: 500;
        color: #608bff;
      }

      .arrtableRow {
        background-color: white !important;
        border-bottom: solid 1px#E2E2E2;
      }
    }
  }
}

.tdProinSpan {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.tihuanFont {
  width: 100%;
  height: 100%;
  background: #608bff;
  border-radius: 4px;
  font-size: 12px;
  font-family: Roboto-Regular, Roboto;
  font-weight: 400;
  color: #ffffff;
  margin: 0 !important;
  text-align: center;
  // line-height: 34px;
}
</style>
