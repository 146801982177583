<template>
  <div>
    <Dialog ref="dialog" :config="config">
      <div class="tishiCon">
        <p>
          {{
            $fanyi(
              "为了提供最好的进口报价，一旦产品到达我们的仓库，就会计算出准确的运费和其他费用报价，所以现在你只支付产品。"
            )
          }}
        </p>
        <p>
          {{
            $fanyi(
              "由于货物的分组和其他数据，我们可以提供一个更好的最终报价的运输。"
            )
          }}
        </p>
        <p>
          {{
            $fanyi(
              "这一细分包括产品的质量控制、合同规定的额外服务，以及在适当情况下合同规定的其他费用。"
            )
          }}
        </p>
      </div>
    </Dialog>
  </div>
</template>
<script>
import Dialog from "../../../../../../components/public/Dialog.vue";
export default {
  data() {
    return {
      config: {
        top: "20vh",
        width: "822px",
        title: this.$fanyi("为什么现在估计装运?"),
        btnTxt: [this.$fanyi("已了解")],
      },
    };
  },
  components: { Dialog },
  computed: {},
  created() { },
  mounted() { },
  methods: {
    open() {
      this.$refs.dialog.open(
        "",
        () => { },
        () => { }
      );
    },
  },
};
</script>
<style lang='scss' scoped='scoped'>
@import "../../../../../../css/mixin";

/deep/ .el-dialog__footer {
  .el-button:last-child {
    border: none !important;
  }
}

.tishiCon {
  width: 733px;
  margin: 0 auto;
  height: 218px;
  font-size: 19px;
  font-weight: 400;
  color: #2f2f2f;
  line-height: 25px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
}
</style>