<template>
  <div class="main" v-if="showverdetalles">
    <el-dialog :title="$fanyi('预计进口产品费用明细 ')" class="verdetall" :visible.sync="showverdetalles" width="580px">
      <div class="one">
        <span>{{ $fanyi('容积约为') }}</span>
        <span>{{ datas.volume }}m³</span>
      </div>
      <p>
        <span>{{ $fanyi('产品费用') }}</span>
        <span class="right"><span>${{ datas.goods_price_us }}</span> <span>({{ datas.goods_price }}￥)</span></span>
      </p>
      <p>
        <span>{{ $fanyi('佣金说明') }}</span>
        <span class="right"><span>${{ datas.service_rate_price_us }} </span> <span>({{ datas.service_rate_price
        }}￥)</span></span>
      </p>
      <p>
        <span>{{ $fanyi('附加服务费用') }}</span>
        <span class="right"><span>${{ datas.option_price_us }} </span> <span>({{ datas.option_price }}￥)</span></span>
      </p>
      <p>
        <span>{{ $fanyi('中国物流费用') }}</span>
        <span class="right"><span>${{ datas.freight_us }} </span> <span>({{ datas.freight }}￥)</span></span>
      </p>
      <div class="two">
        <span>{{ $fanyi('不含运费的产品总成本') }}</span>
        <span><span style="font-size: 16px;
                                font-family: Roboto-Black, Roboto;
                                font-weight: 900;
                                color: #222222;">${{ datas.sum_price_us }}</span> <span> ({{ datas.sum_price
                                }}￥)</span></span>
      </div>
      <p>
        <span>{{ $fanyi('产品成本') }}</span>
        <span class="right"><span>${{ datas.cost_price_us }} </span> <span>({{ datas.cost_price }}￥)</span></span>
      </p>
      <p>
        <span>{{ $fanyi('预估国际运费') }}</span>
        <span class="right"><span>${{ datas.logistics_price_us }} </span> <span>({{ datas.logistics_price
        }}￥)</span></span>
      </p>
      <p>
        <span>{{ $fanyi('预估美国国内运费') }}</span>
        <span class="right"><span>${{ datas.foreign_express_price_us }} </span> <span>({{ datas.foreign_express_price
        }}￥)</span></span>
      </p>
      <div class="there">
        <span>{{ $fanyi('包含运费产品总成本') }}</span> <span> <span style="font-size: 16px;
                                font-family: Roboto-Black, Roboto;
                                font-weight: 900;
                                color: #222222;">${{ datas.total_product_us }} </span><span>({{
                                  datas.total_product
                                }}￥)</span></span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showverdetalles: false,
      datas: '',

    }
  },
  methods: {
    async orderOneGoodsFee(id, wuliuid) {

      const { data } = await this.$api.orderOneGoodsFee({
        detail_id: id,
        offer_id: wuliuid,
      })
      this.datas = data

    },
  },
}
</script>

<style lang="scss" scoped>
/deep/.el-dialog__header {
  line-height: 68px;
  width: 100%;
  height: 68px !important;
  padding: 0 30px;
  border-bottom: 1px solid #f5f5f5;
  margin-bottom: 20px;

  .el-dialog__title {
    height: 28px;
    font-size: 18px;
    font-family: Roboto-Medium, Roboto;
    font-weight: 500;
    color: #222222;
    margin-left: 30px;
  }

  .el-dialog__close {
    margin-top: 15px;
  }
}

/deep/ .el-dialog__body {
  min-height: 358px;
  padding: 0 30px !important;
  padding-bottom: 40px !important;

  .one {
    width: 520px;
    height: 44px;
    background: #f2f2f2;
    border-radius: 4px;
    border: 1px solid #e2e2e2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-family: Roboto-Regular, Roboto;
    font-weight: 400;
    padding: 0 20px;
    color: #222222;
  }

  .two {
    width: 520px;
    height: 44px;
    background: #e9f8fb;
    border-radius: 4px;
    border: 1px solid #49c9e0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-family: Roboto-Regular, Roboto;
    font-weight: 400;
    margin-top: 20px;
    padding: 0 20px;
    color: #222222;
  }

  .there {
    width: 520px;
    height: 44px;
    background: #e4fbf4;
    border-radius: 4px;
    border: 1px solid #1ba37b;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-family: Roboto-Regular, Roboto;
    font-weight: 400;
    padding: 0 20px;
    color: #222222;
  }

  p {
    padding: 0 20px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      &:first-child {
        font-size: 14px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #666666;
      }
    }

    .right {
      span {
        &:first-child {
          font-size: 14px;
          font-family: Roboto-Black, Roboto;
          font-weight: 900;
          color: #222222;
        }

        &:last-child {
          font-size: 14px;
          font-family: Roboto-Regular, Roboto;
          font-weight: 400;
          color: #222222;
        }
      }
    }
  }
}

.main {
  width: 580px;
  height: 536px;
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid #e2e2e2;
}
</style>
