import { render, staticRenderFns } from "./SpecialNote.vue?vue&type=template&id=7829ea09&scoped=true&"
import script from "./SpecialNote.vue?vue&type=script&lang=js&"
export * from "./SpecialNote.vue?vue&type=script&lang=js&"
import style0 from "./SpecialNote.vue?vue&type=style&index=0&id=7829ea09&lang=scss&scoped=scoped&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7829ea09",
  null
  
)

export default component.exports