<template>
  <div class="comblock" :style="!openBlock ? 'height:56px' : ''">
    <div class="blockTitle">
      <span>
        {{ $fanyi('国际费用明细') }}
      </span>
      <div class="imgBtn">
        <img class="jiahaoIcon" v-if="!openBlock" src="../../../../../../assets/pulldown_1.svg"
          @click="openBlock = !openBlock" />
        <img class="jiahaoIcon" v-else src="../../../../../../assets/pulldown_0.svg" @click="openBlock = !openBlock" />
      </div>
    </div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  data() {
    return {
      openBlock: true,
    }
  },
  components: {},
  computed: {},
  created() { },
  methods: {},
}
</script>
<style lang="scss" scoped="scoped">
@import '../../../../../../css/mixin';

.comblock {
  background: #f9f9f9;
  background: #ffffff;
  height: 402px;
  border-radius: 6px;
  border: 1px solid #e2e2e2;
  margin: 0 auto;
  margin-top: 30px;
  width: 1400px;
  border: 1px solid #e2e2e2;
  overflow: hidden;

  .blockTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1400px;
    height: 56px;

    padding: 0 23px 0 20px;
    border-bottom: 1px dashed #e2e2e2;

    span {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
    }

    .imgBtn {
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
}
</style>
